import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const getListWebCampaigns = createAsyncThunk(
    'getListWebTeams',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.CampaignsAPI}/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );

export const getListWebReportedCampaigns = createAsyncThunk(
    'getListWebReportedTeams',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.ReportAPI}/ad-campaign/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );

  export const updateCampaignsStatus = createAsyncThunk(
    'updateTeamsStatus',
    async (body) => {
      const obj = {
        url: `${APIEndPoints.CampaignsAPI}/list`,
        method: 'PUT',
        body:JSON.stringify(body)
      };
      return await API(obj);
    }
  );


  