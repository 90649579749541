import React, { Component, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import {
  createStaticPage,
  getStaticPageContent,
  updateStaticPage,
} from "../../store/actions/StaticPageActions";
import { useRoutes, useParams, useNavigate } from "react-router-dom";
// import PageTitle from "../../../layouts/PageTitle";

const CreateStaticPage = ({}) => {
  const dispatch = useDispatch();
  const router = useParams();
  const navigate = useNavigate();
  const [editorContent, setEditorContent] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const payload = {
      type: data?.type,
      content: editorContent,
    };
    if (router?.slug) {
      dispatch(updateStaticPage(payload)).then((res) => {
        if (res.payload.id) {
          toast.success("Page created successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
        } else {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
        }
      });
    } else {
      dispatch(createStaticPage(payload)).then((res) => {
        if (res.payload.id) {
          toast.success("Page created successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
        } else {
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
        }
      });
    }
    setEditorContent("");
    navigate("/pages");
  };

  useEffect(() => {
    if (router) {
      dispatch(getStaticPageContent(router?.slug)).then((res) => {
        if (res?.payload?.id) {
          setValue("type", res?.payload?.type);
          setValue("page_name", res?.payload?.type?.replace("-", " "));
          setEditorContent(res?.payload?.content);
        }
      });
    }
  }, [router]);

  // console.log(router);
  return (
    <>
      {/* <PageTitle activeMenu="CkEditor" motherMenu="Form" pageContent="CkEditor" /> */}
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card-header">
                <h4 className="card-title ">
                  {router?.slug ? "Update" : "Create"} Page
                </h4>
              </div>
              <div className="card-body custom-ekeditor">
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Page Name</label>
                    <input
                      type="text"
                      className="form-control text-capitalize"
                      placeholder="e.g About Us"
                      {...register("page_name", { required: true })}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Page Slug</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g about-us"
                      {...register("type", { required: true })}
                    />
                  </div>
                </div>
                <CKEditor
                  editor={ClassicEditor}
                  onReady={(editor) => {}}
                  data={editorContent}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setEditorContent(data);
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                  // config={{
                  //     toolbar: [
                  //         'undo', 'redo',
                  //         '|', 'heading',
                  //         '|', 'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor',
                  //         '|', 'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'code',
                  //         '|', 'link', 'uploadImage', 'blockQuote', 'codeBlock',
                  //         '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent'
                  //     ],
                  // }}
                  // {...register("content", { required: false })}
                />
              </div>
              <div className="card-footer d-flex justify-content-between">
                <button type="submit" className="btn btn-primary">
                  {router?.slug ? "Update" : "Create"} Page
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStaticPage;
