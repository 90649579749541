import React, { Fragment, useContext } from "react";
import { useDispatch , useSelector } from 'react-redux';
/// React router dom
import { Link } from "react-router-dom";
import { navtoggle } from "../../../store/actions/AuthActions";
import { ThemeContext } from "../../../context/ThemeContext";
import logo2 from "../../../images/mbLogo.svg";
const NavHader = () => {

  const { navigationHader, background } = useContext(
    ThemeContext
  );
  const dispatch = useDispatch();
   const sideMenu = useSelector(state => state.sideMenu);
   const handleToogle = () => {
     dispatch(navtoggle());
   };
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
				
          </Fragment>
        ) : (
          <Fragment>
            <img src={logo2} alt="" width='40px' height="40px" />
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
			handleToogle()
        //   openMenuToggle();
        }}
      >
        <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
