import React, { useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Modal,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDiskPlanPrices,
  getCurrentDiskPlans,
} from "../../../store/actions/SubscriptionsAction";
import { useForm } from "react-hook-form";
const DiskSubscriptions = () => {
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [changeInterval, setChangeInterval] = useState("1");
  const [changedPrice, setChangedPrice] = useState();

  const { diskPlansData, loading } = useSelector(
    (state) => state.subscriptionReducer
  );
  useEffect(() => {
    dispatch(getCurrentDiskPlans());
  }, []);

  const handleClose = () => {
    setModalShow(false);
    setSelectedPlan();
  };

  const onSubmit = () => {
    const payload = {
      price_id:
        changeInterval === "1"
          ? selectedPlan?.month_price_id
          : selectedPlan?.year_price_id,
      plan_id: selectedPlan?.id,
      amount: changedPrice,
    };
    dispatch(changeDiskPlanPrices(payload)).then((res) => {
      setModalShow(false);
      setChangeInterval("1");
      setChangedPrice();
      setSelectedPlan();
      dispatch(getCurrentDiskPlans());
    });
  };

//   console.log(
//     changeInterval === "1"
//       ? selectedPlan?.price_monthly
//       : selectedPlan?.price_yearly,
//     selectedPlan
//   );

  return (
    <React.Fragment>
      <div className="badge-demo">
        <PageTitle
          motherMenu="Subcriptions"
          activeMenu="Disk Subscription"
          pageContent="Disk Subscription"
        />

        {loading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <Row>
            {diskPlansData &&
              diskPlansData?.map((plan, i) => (
                <Col lg="4" key={i}>
                  <Card>
                    <Card.Header className="d-block">
                      <Card.Title>{plan?.plan_name}</Card.Title>
                      <Card.Text className="mb-0 subtitle">
                        Storage{" "}
                        {plan.id === 1
                          ? `${plan?.storage?.slice?.(0, 1)}GB`
                          : `${plan?.storage?.slice?.(0, 1)}TB`}
                      </Card.Text>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <ul class="list-group list-group-flush">
                        {plan?.features_list?.map((feature, i) => (
                          <li class="list-group-item d-flex justify-content-between align-items-start">
                            <div style={{ marginRight: "20px" }}>
                              <span class="mb-0 title">{feature?.name}</span>
                              <p className="m-0" style={{ fontSize: "10px" }}>
                                {feature?.description}
                              </p>
                            </div>

                            {feature?.active && (
                              <i className="flaticon-381-success"></i>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Col className="d-flex align-items-center flex-column">
                          <div>Monthly</div>
                          <div>${plan?.price_monthly}</div>
                        </Col>
                        <Col className="d-flex align-items-center flex-column">
                          <div>Yearly</div>
                          <div>${plan?.price_yearly}</div>
                        </Col>
                        <Col className="d-flex align-items-center flex-column">
                          {plan.id !== 1 && (
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => {
                                setSelectedPlan(plan);
                                setModalShow(true);
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
          </Row>
        )}
      </div>
      {selectedPlan && (
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit {selectedPlan?.plan_name} plan
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Check
              inline
              label="Monthy"
              type="radio"
              value="1"
              name="interval"
              defaultChecked={changeInterval === "1" ? true : false}
              onChange={(e) => setChangeInterval(e.target.value)}
            />
            <Form.Check
              inline
              label="Yearly"
              type="radio"
              value="2"
              name="interval"
              defaultChecked={changeInterval === "2" ? true : false}
              onChange={(e) => setChangeInterval(e.target.value)}
            />
            {changeInterval === "1" ? (
              <div class="input-group mt-3">
                <span class="input-group-text">$</span>
                <input
                  type="text"
                  class="form-control"
                  aria-label="Amount (to the nearest dollar)"
                  name="plan_price_monthly"
                  value={
                    changedPrice ? changedPrice : selectedPlan?.price_monthly
                  }
                  onChange={(e) => setChangedPrice(e.target.value)}
                />
              </div>
            ) : (
              <div class="input-group mt-3">
                <span class="input-group-text">$</span>
                <input
                  type="text"
                  class="form-control"
                  aria-label="Amount (to the nearest dollar)"
                  name="plan_price_yearly"
                  value={
                    changedPrice ? changedPrice : selectedPlan?.price_yearly
                  }
                  onChange={(e) => setChangedPrice(e.target.value)}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} variant="light">
              Close
            </Button>
            <Button onClick={() => onSubmit()}>Save</Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default DiskSubscriptions;
