export const APIEndPoints = {
  SignIn: 'api/token/',
  Register: 'api/v1/register',
  UserProfileInfo: 'api/v1/user/profile/info',
  AgentAPI: 'api/v1/admin/agent',
  UsersAPI: 'api/v1/admin/user',
  PasswordRequestAPI:'api/v1/password_resetconfirm/',
  PostAPI:'api/v1/admin/post',
  EventsAPI:'api/v1/admin/event',
  ArticlesAPI:'api/v1/admin/article',
  JobsAPI:'api/v1/admin/job',
  dashboardMetrix: 'api/v1/admin/metrices',
  TeamsAPI: 'api/v1/admin/team',
  CompaniesAPI: 'api/v1/admin/company',
  CampaignsAPI: 'api/v1/admin/ad-campaign',
  ReportAPI: 'api/v1/admin/report',
  SPagesAPI:'api/v1/static-pages',
  DiskSubscription:'api/v1/disk/subscription/plan/stripe/',
  DiskSubscriptionChange:'api/v1/admin/disk/plan'
 };
