import { lazy, Suspense, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
import Index from "./jsx";
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { getUserProfileInfo } from "./store/actions/user-profile.actions";
import "react-toastify/dist/ReactToastify.css";
import "./jsx/components/table/FilteringTable/filtering.css";
import "react-datepicker/dist/react-datepicker.css";
/// Style
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
// import "../src/vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ToastContainer } from "react-toastify";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import SetPasswordPage from "./jsx/pages/SetPassword";
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
function withRouter(Component) {
  const queryClient = new QueryClient();
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

function App(props) {
  const { isAuthenticated } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { userProfileInfo, loading } = useSelector(
    (state) => state.userProfile
  );
  useEffect(() => {
    if(!location.pathname.includes('resetconfirm'))
    checkAutoLogin(dispatch, navigate);
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      checkAutoLogin(dispatch, navigate);
      dispatch(getUserProfileInfo());
    }
  }, [isAuthenticated]);
  const queryClient = new QueryClient();

  let routeblog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/page-register" element={<SignUp />} />
      <Route
        path="password/resetconfirm/:token"
        element={<SetPasswordPage />}
      />
    </Routes>
  );

  // console.log(location.pathname.includes('resetconfirm'));

  if (isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <ToastContainer />
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routeblog}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
