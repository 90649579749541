/// Menu
import React, { useContext, useReducer, useState } from "react";
import { Modal, Collapse } from "react-bootstrap";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { MenuList } from "./Menu";
import { ThemeContext } from "../../../context/ThemeContext";
import { useSelector, useDispatch } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import { createAdminUser, requestPasswordLink } from "../../../store/actions/user-profile.actions";
import { ToastContainer, toast } from "react-toastify";
const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  var d = new Date();
  const [newProject, setNewProject] = useState(false);
  const dispatch = useDispatch();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);
  const [state, setState] = useReducer(reducer, initialState);
  const { userProfileInfo, loading } = useSelector(
    (state) => state.userProfile
  );
  const [btnHeart, setBtnHeart] = useState();

  // For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const payload = {
      email: data?.email,
    first_name:data?.first_name,
    last_name:data?.last_name,
    role:2 // 1 for super admin . 2 for admin
    }
    dispatch(createAdminUser(payload)).then((res) => {
      if(res.payload.message){
        dispatch(requestPasswordLink({email:data?.email}))
        toast.success(res.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
        });
      } else {
        toast.error(res.payload.email[0], {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    });
  }


  return (
    
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
   
      {userProfileInfo?.role === 1 && (
        <Link
          to={"#"}
          className="add-project-sidebar btn btn-primary"
          onClick={() => setNewProject(true)}
        >
          + New Admin
        </Link>
      )}
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {data.title}
                </li>
              );
            } else {
              if (userProfileInfo?.role === 2 && data.role === "admin") {
                return (
                  <li
                    className={` ${
                      state.active === data.title ? "mm-active" : ""
                    }`}
                    key={index}
                  >
                    {data.content && data.content.length > 0 ? (
                      <Link
                        to={"#"}
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {data.iconStyle}{" "}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                    ) : (
                      <Link to={data.to}>
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                    )}
                    <Collapse in={state.active === data.title ? true : false}>
                      <ul
                        className={`${
                          menuClass === "mm-collapse" ? "mm-show" : ""
                        }`}
                      >
                        {data.content &&
                          data.content.map((data, index) => {
                            return (
                              <li
                                key={index}
                                className={`${
                                  state.activeSubmenu === data.title
                                    ? "mm-active"
                                    : ""
                                }`}
                              >
                                {data.content && data.content.length > 0 ? (
                                  <>
                                    <Link
                                      to={data.to}
                                      className={
                                        data.hasMenu ? "has-arrow" : ""
                                      }
                                      onClick={() => {
                                        handleSubmenuActive(data.title);
                                      }}
                                    >
                                      {data.title}
                                    </Link>
                                    <Collapse
                                      in={
                                        state.activeSubmenu === data.title
                                          ? true
                                          : false
                                      }
                                    >
                                      <ul
                                        className={`${
                                          menuClass === "mm-collapse"
                                            ? "mm-show"
                                            : ""
                                        }`}
                                      >
                                        {data.content &&
                                          data.content.map((data, index) => {
                                            return (
                                              <li key={index}>
                                                <Link
                                                  className={`${
                                                    path === data.to
                                                      ? "mm-active"
                                                      : ""
                                                  }`}
                                                  to={data.to}
                                                >
                                                  {data.title}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </Collapse>
                                  </>
                                ) : (
                                  <Link to={data.to}>{data.title}</Link>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    </Collapse>
                  </li>
                );
              } else if (userProfileInfo?.role === 1) {
                return (
                  <li
                    className={` ${
                      state.active === data.title ? "mm-active" : ""
                    }`}
                    key={index}
                  >
                    {data.content && data.content.length > 0 ? (
                      <Link
                        to={"#"}
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {data.iconStyle}{" "}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                    ) : (
                      <Link to={data.to}>
                        {data.iconStyle}
                        <span className="nav-text">{data.title}</span>
                      </Link>
                    )}
                    <Collapse in={state.active === data.title ? true : false}>
                      <ul
                        className={`${
                          menuClass === "mm-collapse" ? "mm-show" : ""
                        }`}
                      >
                        {data.content &&
                          data.content.map((data, index) => {
                            return (
                              <li
                                key={index}
                                className={`${
                                  state.activeSubmenu === data.title
                                    ? "mm-active"
                                    : ""
                                }`}
                              >
                                {data.content && data.content.length > 0 ? (
                                  <>
                                    <Link
                                      to={data.to}
                                      className={
                                        data.hasMenu ? "has-arrow" : ""
                                      }
                                      onClick={() => {
                                        handleSubmenuActive(data.title);
                                      }}
                                    >
                                      {data.title}
                                    </Link>
                                    <Collapse
                                      in={
                                        state.activeSubmenu === data.title
                                          ? true
                                          : false
                                      }
                                    >
                                      <ul
                                        className={`${
                                          menuClass === "mm-collapse"
                                            ? "mm-show"
                                            : ""
                                        }`}
                                      >
                                        {data.content &&
                                          data.content.map((data, index) => {
                                            return (
                                              <li key={index}>
                                                <Link
                                                  className={`${
                                                    path === data.to
                                                      ? "mm-active"
                                                      : ""
                                                  }`}
                                                  to={data.to}
                                                >
                                                  {data.title}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </Collapse>
                                  </>
                                ) : (
                                  <Link to={data.to}>{data.title}</Link>
                                )}
                              </li>
                            );
                          })}
                      </ul>
                    </Collapse>
                  </li>
                );
              }
            }
          })}
        </ul>
        {/* <div className="copyright">
            <p><strong>Fasto React Admin Dashboard</strong> © {d.getFullYear()} All Rights Reserved</p>
            <p className="fs-12">Made with 
              <span className={`heart ${ btnHeart ? 'heart-blast' : ''}`}
                onClick={()=>setBtnHeart(!btnHeart)}
              ></span> 
              by DexignZone</p>
          </div> */}
      </PerfectScrollbar>
      <Modal
        className="modal fade"
        size="lg"
        id="addProjectSidebar"
        show={newProject}
        onHide={setNewProject}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Admin User</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setNewProject(false)}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="text-black font-w500">First Name</label>
                    <input type="text" {...register("first_name", { required: true })} className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="text-black font-w500">Last Name</label>
                    <input type="text" {...register("last_name", { required: true })} className="form-control" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="text-black font-w500">Email Id</label>
                <input type="email"  {...register("email", { required: true })} className="form-control" />
              </div>
              {/* <div className="form-group">
                <label className="text-black font-w500">Role</label>
                <input type="text" className="form-control" />
              </div> */}
              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? 'loading...' : 'CREATE USER' }
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
    
  );
};

export default SideBar;
