import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const createStaticPage = createAsyncThunk(
    'createStaticPage',
    async (body) => {
  
      const obj = {
        url: `${APIEndPoints.SPagesAPI}/`,
        method: 'POST',
        body:JSON.stringify(body)
      };
      return await API(obj);
    }
  );

  export const getStaticPageList = createAsyncThunk(
    'getStaticPageList',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.SPagesAPI}/`,
        method: 'GET',
      };
      return await API(obj);
    }
  );


  export const getStaticPageContent = createAsyncThunk(
    'getStaticPageContent',
    async (id) => {
  
      const obj = {
        url: `${APIEndPoints.SPagesAPI}/${id}/`,
        method: 'GET',
      };
      return await API(obj);
    }
  );

 
  export const updateStaticPage = createAsyncThunk(
    'updateStaticPage',
    async (body) => {
  
      const obj = {
        url: `${APIEndPoints.SPagesAPI}/${body.type}/`,
        method: 'PATCH',
        body:JSON.stringify({content:body.content})
      };
      return await API(obj);
    }
  );