import { createReducer } from '@reduxjs/toolkit';
import { getCurrentDiskPlans, changeDiskPlanPrices } from '../actions/SubscriptionsAction';

const initialState = {
    msg: '',
    loading: true,
    error: false,
    diskPlansData:null, 
    changeDiskPlan:null
};

export const subscriptionReducer = createReducer(initialState, builder => {
    builder
        .addCase(getCurrentDiskPlans.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getCurrentDiskPlans.fulfilled, (state, action) => {
            state.loading = false;
            state.diskPlansData = action?.payload;
        })
        .addCase(getCurrentDiskPlans.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(changeDiskPlanPrices.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(changeDiskPlanPrices.fulfilled, (state, action) => {
            state.loading = false;
            state.changeDiskPlan = action?.payload;
        })
        .addCase(changeDiskPlanPrices.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        
});