import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import PostsReducer, {toggleMenu} from './reducers/PostsReducer';
import thunk from 'redux-thunk';
import { AuthReducer } from './reducers/AuthReducer';
import { userProfileReducer } from './reducers/user-profile.reducer';
import { subscriptionReducer } from './reducers/subscriptionReducer';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    sideMenu: toggleMenu,
    auth: AuthReducer,
    userProfile: userProfileReducer,
    subscriptionReducer
	
});

export const store = createStore(reducers,  composeEnhancers(middleware));
