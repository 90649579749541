import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const NAVTOGGLE = "NAVTOGGLE";


export function signupAction(email, password, navigate) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 1000
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate("/");
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  localStorage.removeItem("userDetails");
  localStorage.removeItem("access");
  navigate("/login");
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        const {exp} = jwtDecode(response.access);
        const saveResponse = {
          kind: "identitytoolkit#VerifyPasswordResponse",
          localId: "",
          email: email,
          displayName: "",
          idToken: response.access,
          registered: response.role !== null ? true : false,
          refreshToken:response.refresh,
          expiresIn: "3600",
          expireDate:moment.utc(exp * 1000).format()
      }
        if (response.role !== null) {
          saveTokenInLocalStorage(saveResponse);
        //   runLogoutTimer(dispatch, exp * 1000, navigate);
          dispatch(loginConfirmedAction({
            email: email,
            idToken: response.access,
            localId: response.role,
            expiresIn: "3600",
            refreshToken: response.refresh,
        }));
          navigate("/dashboard");
        } else {
            dispatch(loginFailedAction("You don't have access to this area!"));
        }
      })
      .catch((error) => {
        // const errorMessage = formatError(error.detail.data);
        dispatch(loginFailedAction(error));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
