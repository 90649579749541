import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const getCurrentDiskPlans = createAsyncThunk(
    'getCurrentDiskPlans',
    async (body) => {
  
      const obj = {
        url: `${APIEndPoints.DiskSubscription}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );


export const changeDiskPlanPrices = createAsyncThunk(
    'changeDiskPlanPrices',
    async (body) => {
  
      const obj = {
        url: `${APIEndPoints.DiskSubscriptionChange}`,
        method: 'PUT',
        body:JSON.stringify(body)
      };
      return await API(obj);
    }
  );

  