export const MenuList = [
    //Dashboard
    {
        title: 'Dashboard',	
        classsChange: 'mm-collapse',		
        iconStyle:<i className="flaticon-layout" />,
        to: 'dashboard',
        role:'admin'
        // content: [
        //     {
        //         title: 'Dashboard Light',
        //         to: 'dashboard',					
        //     },
        //     {
        //         title: 'Dashboard Dark',
        //         to: 'dashboard-dark',
        //     },
        //     {
        //         title: 'Project',
        //         to: 'projects',
        //     },
        //     {
        //         title: 'Contacts',
        //         to: 'contacts',
        //     },
        //     {
        //         title: 'Task List',
        //         to: 'task-list',
                
        //     },
		// 	{
        //         title: 'Calendar',
        //         to: 'calendar',                
        //     },
		// 	{
        //         title: 'Messages',
        //         to: 'messages',                
        //     },
		// 	{
        //         title: 'Task',
        //         to: 'task',                
        //     },    
        // ],
    },
    {
        title: 'Admin Users',	
        classsChange: 'mm-collapse',		
        iconStyle:<i className="flaticon-user" />,
        to: 'admin-users',
        role:'superAdmin'
    },
   
    //Pages
    {
        title:'Users',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-user-9"></i>,
        // to: 'users',
        role:'admin',
        content: [
            {
                title: 'All Users',
                to: 'users',					
            },
            {
                title: 'Reported Users',
                to: 'reported-users',
            },
              
        ],
    },
    {
        title:'Posts',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-news"></i>,
        // to: 'posts',
        role:'admin',
        content: [
            {
                title: 'All posts',
                to: 'posts',					
            },
            {
                title: 'Reported Posts',
                to: 'reported-posts',
            },
              
        ],
    },
    {
        title:'Events',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-calendar"></i>,
        // to: 'events',
        role:'admin',
        content: [
            {
                title: 'All events',
                to: 'events',					
            },
            {
                title: 'Reported events',
                to: 'reported-events',
            },
              
        ],
    },
    {
        title:'Articles',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-bookmark-1"></i>,
        // to: 'articles',
        role:'admin',
        content: [
            {
                title: 'All articles',
                to: 'articles',					
            },
            {
                title: 'Reported articles',
                to: 'reported-articles',
            },
              
        ],
    },
    {
        title:'Jobs',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-briefcase"></i>,
        // to: 'jobs',
        role:'admin',
        content: [
            {
                title: 'All jobs',
                to: 'jobs',					
            },
            {
                title: 'Reported jobs',
                to: 'reported-jobs',
            },
              
        ],
    },
    {
        title:'Teams',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-networking-1"></i>,
        // to: 'teams',
        role:'admin',
        content: [
            {
                title: 'All teams',
                to: 'teams',					
            },
            {
                title: 'Reported teams',
                to: 'reported-teams',
            },
              
        ],
    },
    {
        title:'Company',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-home-3"></i>,
        // to: 'teams',
        role:'admin',
        content: [
            {
                title: 'All companies',
                to: 'companies',					
            },
            {
                title: 'Reported companies',
                to: 'reported-companies',
            },
              
        ],
    },
    {
        title:'Pages',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-072-printer"></i>,
        // to: 'teams',
        role:'superAdmin',
        content: [
            {
                title: 'All Pages',
                to: 'pages',					
            },
            {
                title: 'Create Page',
                to: 'new-page',
            },
              
        ],
    },
    {
        title:'Campaigns',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-072-printer"></i>,
        // to: 'teams',
        role:'superAdmin',
        content: [
            {
                title: 'All Campaigns',
                to: 'campaigns',					
            },
            {
                title: 'Reported Campaigns',
                to: 'reported-campaigns',
            },
              
        ],
    },
    {
        title:'Subscriptions',
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-admin"></i>,
        // to: 'teams',
        role:'superAdmin',
        content: [
            {
                title: 'Disk Subscription',
                to: '/subscriptions/disk-subscription',
            },
            {
                title: 'Job Subscription',
                to: 'new-page',
            },
            {
                title: 'Premium Subscription',
                to: 'new-page',
            },
              
        ],
    },
]