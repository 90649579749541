import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';
// import { jsonToFormData } from 'v4/lib/utils';


export const userProfileAddInfo = createAsyncThunk(
  'userProfileAddInfo',
  async (body, isFormData = false) => {
    const obj = {
      url: `${APIEndPoints.UserProfileInfo}/`,
      method: 'PUT',
      // body: jsonToFormData(body),
      body: isFormData ? body : body,
      isFormData,
    };
    return await API(obj);
  }
);

export const getUserProfileInfo = createAsyncThunk(
  'getUserProfileInfo',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.UserProfileInfo}/`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const createAdminUser = createAsyncThunk(
  'createAdminUser',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.AgentAPI}/register`,
      method: 'POST',
      body:JSON.stringify(body)
    };
    return await API(obj);
  }
);

export const getListAdminUser = createAsyncThunk(
  'getListAdminUser',
  async ({ pageParam = 1, size = 10, search }) => {
    const obj = {
      url: `${APIEndPoints.AgentAPI}/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const getListWebUser = createAsyncThunk(
  'getListWebUser',
  async ({ pageParam = 1, size = 10, search }) => {

    const obj = {
      url: `${APIEndPoints.UsersAPI}/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
      method: 'GET',
    };
    return await API(obj);
  }
);


export const getListReportedWebUser = createAsyncThunk(
  'getListReportedWebUser',
  async ({ pageParam = 1, size = 10, search }) => {

    const obj = {
      url: `${APIEndPoints.ReportAPI}/user/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const requestPasswordLink = createAsyncThunk(
  'requestPasswordLink',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.AgentAPI}/password/reset`,
      method: 'POST',
      body:JSON.stringify(body)
    };
    return await API(obj);
  }
);

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.PasswordRequestAPI}`,
      method: 'POST',
      body:JSON.stringify(body)
    };
    return await API(obj);
  }
);

export const updateUserStatus = createAsyncThunk(
  'updateUserStatus',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.UsersAPI}/restrict`,
      method: 'PUT',
      body:JSON.stringify(body)
    };
    return await API(obj);
  }
);

export const getDahsboardData = createAsyncThunk(
  'getDahsboardData',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.dashboardMetrix}?metrices=total_users,active_user_past_90d,new_users_past_7d,new_users_past_30d,new_users_trend_chart`,
      method: 'GET',
    };
    return await API(obj);
  }
);

export const changeUserAdminRole = createAsyncThunk(
  'changeUserAdminRole',
  async (body) => {
    const obj = {
      url: `${APIEndPoints.AgentAPI}/register`,
      method: 'PUT',
      body:JSON.stringify(body)
    };
    return await API(obj);
  }
);