import React, { useContext } from "react";
import {  Routes, Route, Outlet  } from "react-router-dom";
import { useSelector } from "react-redux";

/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Index from "./components/Dashboard/Index";


/// Pages
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'
import AdminUsersList from "./pages/AdminUsers";
import WebsiteUsersList from "./pages/WebUserList";
import WebsitePostsList from "./pages/WebPostsList";
import WebsiteEventsList from "./pages/WebEventsList";
import WebsiteArticlesList from "./pages/WebArticlesList";
import WebsiteJobsList from "./pages/WebJobsList";
import WebsiteTeamsList from "./pages/WebTeamsList";
import WebsiteUsersReportedList from "./pages/WebUserReportedList";
import WebsitePostsReportedList from "./pages/WebPostsReportedList";
import WebsiteEventsReportedList from "./pages/WebEventsReportedList";
import WebsiteArticlesReportedList from "./pages/WebArticlesReportedList";
import WebsiteJobsReportedList from "./pages/WebJobsReportedList";
import WebsiteTeamsReportedList from "./pages/WebTeamsReportedList";
import WebsiteCompaniesList from "./pages/WebCompaniesList";
import WebsiteCompaniesReportedList from "./pages/WebCompaniesReportedList";
import CreateStaticPage from "./pages/CreatePage";
import WebsitePagesList from "./pages/WebStaticPageList";
import SetPasswordPage from "./pages/SetPassword";

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import { ThemeContext } from "../context/ThemeContext";
import DiskSubscriptions from "./pages/Subcriptions/disk-subscriptions";
import WebsiteCampaignList from "./pages/WebCampaignList";
import WebsiteCampaignsReportedList from "./pages/WebCampaignsReportedList";



const Markup = () => {

  const allroutes = [
    /// Dashboard
    { url: "", component: <Index /> },
    { url: "dashboard", component: <Index /> },
    { url: "admin-users", component: <AdminUsersList /> },
    { url: "users", component: <WebsiteUsersList /> },
    { url: "reported-users", component: <WebsiteUsersReportedList /> },
    { url: "posts", component: <WebsitePostsList /> },
    { url: "reported-posts", component: <WebsitePostsReportedList />},
    { url: "events", component: <WebsiteEventsList /> },
    { url: "reported-events", component: <WebsiteEventsReportedList /> },
    { url: "articles", component: <WebsiteArticlesList /> },
    { url: "reported-articles", component: <WebsiteArticlesReportedList /> },
    { url: "jobs", component: <WebsiteJobsList /> },
    { url: "reported-jobs", component: <WebsiteJobsReportedList /> },
    { url: "teams", component: <WebsiteTeamsList /> },
    { url: "reported-teams", component: <WebsiteTeamsReportedList /> },
    { url: "companies", component: <WebsiteCompaniesList /> },
    { url: "reported-companies", component: <WebsiteCompaniesReportedList /> },
    { url: "campaigns", component: <WebsiteCampaignList /> },
    { url: "reported-campaigns", component: <WebsiteCampaignsReportedList /> },
    { url: "pages", component: <WebsitePagesList /> },
    { url: "new-page", component: <CreateStaticPage /> },
    { url: "edit-page/:slug", component: <CreateStaticPage /> },
    { url: "/subscriptions/disk-subscription", component: <DiskSubscriptions /> },
    /// pages    
    { url: 'page-lock-screen', component: <LockScreen /> },    
    { url: 'page-error-400', component: <Error400/> },
    { url: 'page-error-403', component: <Error403/> },
    { url: 'page-error-404', component: <Error404/> },
    { url: 'page-error-500', component: <Error500/> },
    { url: 'page-error-503', component: <Error503/> },
  ]

  return (
       <>
          <Routes>
            <Route path='page-lock-screen' element= {<LockScreen />} />
            <Route path='page-error-400' element={<Error400/>} />
            <Route path='page-error-403' element={<Error403/>} />
            <Route path='page-error-404' element={<Error404/>} />
            <Route path='page-error-500' element={<Error500/>} />
            <Route path='page-error-503' element={<Error503/>} />            
            <Route  element={<MainLayout />} > 
                {allroutes.map((data, i) => (
                  <Route
                    key={i}
                    exact
                    path={`${data.url}`}
                    element={data.component}
                  />
                ))}
            </Route>
          </Routes>       
          <ScrollToTop />          
       </>
  )
}

function MainLayout(){
  const sideMenu = useSelector(state => state.sideMenu);
  const { sidebariconHover } = useContext(ThemeContext);
  return (
    <>
      <div 
        className={`show ${ sideMenu ? "menu-toggle" : ""} ${sidebariconHover ? "iconhover-toggle": ""}`}
        id="main-wrapper"         
      >      
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
            <div className="container-fluid">
              <Outlet />                
            </div>
        </div>
        <Footer />
      </div>
    </>
  )
};
export default Markup
