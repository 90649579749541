import axios from 'axios';
import swal from "sweetalert";
import {APIEndPoints} from '../store/common/endPoint';
import API from '../store/common/api';
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `${APIEndPoints.SignIn}`,
        postData,
    );
}

export async function login(email, password) {
    const postData = {
        email,
        password,
        // returnSecureToken: true,
    };
    const obj = {
        url: `${APIEndPoints.SignIn}`,
        method: 'POST',
        body: JSON.stringify(postData),
        isNoToken: true,
      };
      return await API(obj);
    // return axios.post(
    //     `${APIEndPoints.SignIn}`,
    //     postData,
    // );
}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':            
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
           swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'INVALID_PASSWORD':
            swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
    // console.log(tokenDetails);
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    localStorage.setItem('access', tokenDetails.idToken);
}

export function runLogoutTimer(dispatch, timer, navigate) {
    // console.log('navigate', navigate);
    // console.log('dispatch', dispatch);
    // console.log('timer', timer);
    setTimeout(() => {
        // dispatch(Logout(history));
        // dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();
    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    // console.log(todaysDate > expireDate);
    runLogoutTimer(dispatch, timer, navigate);
}

export function isLogin() {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (tokenDetailsString) {
        return true;
    }else{
        return false;
    }
}