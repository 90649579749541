import React from "react";
import "./loader.scss";
import { UsersIcon, HomeIcon, Brifecase } from "../Icons";

const SectionLoader = () => {
  return (
    // <div className="noFreezeSpinner">
    //   <div id="#noFreezeSpinner">
    //     <div>
    //       <div as="i" display="flex" justifyContent="center"><UsersIcon width={20} height={20} color="#3D5A80" /></div>
    //       <div as="i" display="flex" justifyContent="center"><HomeIcon width={20} height={20} color="#3D5A80" /></div>
    //       <div as="i" display="flex" justifyContent="center"><Brifecase width={20} height={20} color="#3D5A80" /></div>
    //       <div></div>
    //     </div>
    //   </div>
    // </div>
    <div id="preloader">
      <div className="sk-three-bounce">
        <div className="sk-child sk-bounce1"></div>
        <div className="sk-child sk-bounce2"></div>
        <div className="sk-child sk-bounce3"></div>
      </div>
    </div>
  );
};

export default SectionLoader;
