import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { resetPassword } from "../../store/actions/user-profile.actions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
const SetPasswordPage = ({ history }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, formState: { errors }, handleSubmit, watch } = useForm({});
  const { token } = useParams();
  const password = useRef({});
  password.current = watch("password", "");
  

  const submitHandler = (data) => {
    console.log(data);
   const payload = {
      password:data?.password,
      token:token
  }
  dispatch(resetPassword(payload)).then((res) => {

   if(res?.payload?.status === "OK"){
      toast.success("Password set, login to continue", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
      });
      navigate('/login');
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      });
    }
  });
    // navigate("/dashboard");
  };

  return (
    <div className="authincation">
      <ToastContainer />
      <div className="container">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <h4 className="text-center mb-4">Account Locked</h4>
                    <form className="needs-validation" onSubmit={e => e.preventDefault()} novalidate>
                      <div className="form-group has-validation">
                        <label for="password">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="New Password"
                          {...register("password", {
                            required: "You must specify a password",
                            minLength: {
                              value: 8,
                              message:
                                "Password must have at least 8 characters",
                            },
                          })}
                        />
                        {errors.password && (
                          <div className="invalid-feedback" style={{"display":"block"}}>
                            {errors?.password?.message}
                          </div>
                        )}
                      </div>
                      
                      <div className="form-group">
                        <label>
                          <strong>Confirm Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          {...register('confirmpassword', {
                            validate: (value) =>
                              value === password?.current ||
                              "The passwords do not match",
                          })}
                        />
                        {errors.confirmpassword && (
                          <div className="invalid-feedback" style={{"display":"block"}}>
                            {errors?.confirmpassword?.message}
                          </div>
                        )}
                      </div>
                      <div className="text-center">
                        <button type="submit" onClick={handleSubmit(submitHandler)} className="btn btn-primary btn-block">Set Password</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetPasswordPage;
