import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../common/api';
import { APIEndPoints } from '../common/endPoint';

export const getListWebPosts = createAsyncThunk(
    'getListWebPosts',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.PostAPI}/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );

export const getListWebReportedPosts = createAsyncThunk(
    'getListWebReportedPosts',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.ReportAPI}/post/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );

  export const updatePostStatus = createAsyncThunk(
    'updatePostStatus',
    async (body) => {
      const obj = {
        url: `${APIEndPoints.PostAPI}/list`,
        method: 'PUT',
        body:JSON.stringify(body)
      };
      return await API(obj);
    }
  );


  export const getListWebEvents = createAsyncThunk(
    'getListWebEvents',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.EventsAPI}/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );


  export const getListWebReportedEvents = createAsyncThunk(
    'getListWebReportedEvents',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.ReportAPI}/event/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );


  export const updateEventstatus = createAsyncThunk(
    'updateEventstatus',
    async (body) => {
      const obj = {
        url: `${APIEndPoints.EventsAPI}/list`,
        method: 'PUT',
        body:JSON.stringify(body)
      };
      return await API(obj);
    }
  );

  export const getListWebArticles = createAsyncThunk(
    'getListWebArticles',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.ArticlesAPI}/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );

  export const getListWebReportedArticles = createAsyncThunk(
    'getListWebArticles',
    async ({ pageParam = 1, size = 10, search }) => {
  
      const obj = {
        url: `${APIEndPoints.ReportAPI}/article/list?page=${pageParam}&page_size=${size}&query=${search ? search : ''}`,
        method: 'GET',
      };
      return await API(obj);
    }
  );


  export const updateArticlestatus = createAsyncThunk(
    'updateArticlestatus',
    async (body) => {
      const obj = {
        url: `${APIEndPoints.ArticlesAPI}/list`,
        method: 'PUT',
        body:JSON.stringify(body)
      };
      return await API(obj);
    }
  );