import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  getListWebCompanies,
  updateCompaniesStatus,
} from "../../store/actions/WebCompanyActions";
import {
  getNextPageFromURL,
  formatLocation,
  flattenQueryResults,
} from "../../utils/utils";
import { Modal, Collapse } from "react-bootstrap";
// import data from "../components/table/tableData";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { getUserFullName } from "../../utils/commons";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
const WebsiteCompaniesList = () => {
  const { changeBackground, background } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState();
  const [newProject, setNewProject] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [datePeriod, setDatePeriod] = useState();
  const [suspenseUser, setSuspenseUser] = useState();
  const today = moment();
  const columns = [
    "ID",
    "Name",
    "Logo",
    "Location",
    "Industry",
    "Created",
    "Status",
    "Action",
  ];
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const calendaricon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
    >
      <mask id="ipSApplication0">
        <g fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="4">
          <path strokeLinecap="round" d="M40.04 22v20h-32V22"></path>
          <path
            fill="#fff"
            d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
          ></path>
        </g>
      </mask>
      <path
        fill="currentColor"
        d="M0 0h48v48H0z"
        mask="url(#ipSApplication0)"
      ></path>
    </svg>
  );
  const {
    // data: adminUsersList,
    data = { pages: [] },
    isFetching: adminCompaniesListFetching,
    isLoading: adminCompaniesListLoading,
    refetch: refetchAdminCompaniesList,
    fetchNextPage: adminCompaniesListFetchNextPage,
    hasNextPage: adminCompaniesListHasNextPage,
    hasPreviousPage: adminCompaniesListHasPrevPage,
    fetchPreviousPage: adminCompaniesListFetchPrevPage,
  } = useInfiniteQuery({
    queryKey: ["admin-event-list", searchQuery],
    queryFn: async ({ queryKey, pageParam }) => {
      const resp = (
        await dispatch(getListWebCompanies({ pageParam, search: queryKey?.[1] }))
      )?.payload;
      return resp;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    getPreviousPageParam: (lastPage) => getNextPageFromURL(lastPage?.previous),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
    refetchAdminCompaniesList();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      refetchAdminCompaniesList();
    }
  }, [searchQuery]);

  const sort = 10;

  const activePag = useRef(0);

  const allCompanies = flattenQueryResults(data) ?? [];

  const getUserStatusMessage = (status) => {
    switch (status) {
      case 1:
        return { message: "Active", state: "success" };
      case 2:
        return { message: "Banned", state: "danger" };
      case 3:
        return { message: "Suspended", state: "warning" };
      // case 4:
      //   return { message: "Banned", state: "danger" };
      // case 5:
      //   return { message: "Suspended", state: "warning" };
      default:
        return { message: "Unverified", state: "info" };
    }
  };

  const handleRerictUser = (action, id) => {
    if (action === "SUSPENDED") {
      setNewProject(true);
      setSuspenseUser(id);
    } else {
      dispatch(
        updateCompaniesStatus({
          action: action, //SUSPENDED //BANNED
          suspended_till: moment().format(),
          entity_id: id,
        })
      ).then((res) => {
        if (res.payload.message) {
          refetchAdminCompaniesList();
          toast.success(res.payload.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
          setNewProject(false);
          setSuspenseUser();
        } else {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
          });
        }
      });
    }
  };

  const handleSuspendUser = () => {
    dispatch(
      updateCompaniesStatus({
        action: "SUSPENDED", //SUSPENDED //BANNED
        suspended_till: datePeriod
          ? moment(datePeriod).utc().format()
          : moment(startDate).utc().format(),
        entity_id: suspenseUser,
      })
    ).then((res) => {
      if (res.payload.message) {
        refetchAdminCompaniesList();
        toast.success(res.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
        });
        setNewProject(false);
        setSuspenseUser();
      } else {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
      setDatePeriod();
      setStartDate(new Date());
    });
  };

  console.log(allCompanies)

  return (
    <Fragment>
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Companies</h4>
          </div>
          <div className="card-body">
              
                <div className="w-100 table-responsive">
                <div id="example_wrapper" className="dataTables_wrapper">
                  <div id="example_filter" class="dataTables_filter">
                    <label>
                      Search:
                      <input
                        type="search"
                        class=""
                        placeholder=""
                        aria-controls="example"
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </label>
                  </div>
                  {adminCompaniesListLoading || adminCompaniesListFetching ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                  <table id="example3" className="display w-100 dataTable">
                    <thead>
                      <tr role="row">
                        {columns.map((d, i) => (
                          <th key={i}>{d}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {allCompanies?.length ? (
                        allCompanies?.map((d, i) => (
                          <tr key={i}>
                            <Fragment key={i}>
                              <td valign="top">{d?.id}</td>
                              <td valign="top" width="200px">
                                {d.name}
                              </td>
                              <td valign="top">
                                {d?.image ? (
                                  <Image
                                    src={d?.image}
                                    rounded
                                    width={80}
                                    height={80}
                                  />
                                ) : (
                                  "No Image"
                                )}
                              </td>
                              <td valign="top">
                                {d?.city ? formatLocation(d) : d?.location}
                              </td>
                              <td valign="top">
                                {d?.company_type}
                              </td>
                              <td valign="top">
                                {moment(d?.created_at).format(
                                  "MM/DD/YYYY hh:mm A"
                                )}
                              </td>
                              <td valign="top">
                                <span
                                  className={`badge badge-rounded badge-${
                                    getUserStatusMessage(d?.status)?.state
                                  }`}
                                >
                                  {getUserStatusMessage(d?.status)?.message}
                                </span>
                              </td>
                              <td valign="top">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant={
                                      getUserStatusMessage(d?.status)?.state
                                    }
                                    className="light sharp i-false"
                                  >
                                    {svg1}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleRerictUser("ACTIVE", d?.id)
                                      }
                                    >
                                      Activate
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleRerictUser("SUSPENDED", d?.id)
                                      }
                                    >
                                      Suspend
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleRerictUser("BANNED", d?.id)
                                      }
                                    >
                                      Ban
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </Fragment>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                    <tfoot>
                      <tr role="row">
                        {columns.map((d, i) => (
                          <th key={i}>{d}</th>
                        ))}
                      </tr>
                    </tfoot>
                  </table>
              )}
                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                    <div className="dataTables_info">
                      Showing {activePag.current * sort + 1} to{" "}
                      {allCompanies?.length} of {data?.pages[0]?.count} entries
                    </div>
                    <div className="dataTables_paginate paging_simple_numbers">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="example5_paginate"
                      >
                        {adminCompaniesListHasPrevPage && (
                          <Link
                            className="paginate_button previous disabled"
                            onClick={() => adminCompaniesListFetchPrevPage()}
                          >
                            Previous
                          </Link>
                        )}

                        {adminCompaniesListHasNextPage && (
                          <Link
                            className="paginate_button next"
                            onClick={() => adminCompaniesListFetchNextPage()}
                          >
                            Next
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        size="md"
        id="addProjectSidebar"
        show={newProject}
        onHide={setNewProject}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Select suspension period</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setNewProject(false)}
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label className="text-black font-w500">Select Period</label>
              <select
                onChange={(e) => setDatePeriod(e.target.value)}
                placeholder="Select Period"
                className="form-control"
              >
                <option>Choose period</option>
                <option value={moment().add(1, "week").format()}>1 Week</option>
                <option value={moment().add(1, "month").format()}>
                  1 Month
                </option>
                <option value={moment().add(1, "year").format()}>1 Year</option>
              </select>
            </div>
            <div className="d-flex justify-content-center">OR</div>
            <div className="form-group date-picker-full">
              <label className="text-black font-w500">Pick a date</label>
              <br />
              <DatePicker
                showIcon
                icon={calendaricon}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control w-100"
                dateFormat="MM-dd-yyyy"
                minDate={new Date()}
              />
            </div>
            {/* <div className="form-group">
                <label className="text-black font-w500">Role</label>
                <input type="text" className="form-control" />
              </div> */}
            <div className="form-group">
              <button
                onClick={() => handleSuspendUser()}
                className="btn btn-primary"
              >
                Suspend
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
export default WebsiteCompaniesList;
