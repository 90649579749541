import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { getListAdminUser, changeUserAdminRole } from "../../store/actions/user-profile.actions";
import {
  getNextPageFromURL,
  formatLocation,
  flattenQueryResults,
} from "../../utils/utils";
import data from "../components/table/tableData";
import { Link } from "react-router-dom";
import SectionLoader from "../components/loader/sectionLoader";
import Spinner from "react-bootstrap/Spinner";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
const AdminUsersList = () => {
  const { changeBackground, background } = useContext(ThemeContext);
  const { userProfileInfo, loading } = useSelector(
    (state) => state.userProfile
  );
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState();
  const columns = ["Name", "Email", "Location", "Role", "Action"];
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const {
    data = { pages: [] },
    isFetching: adminUsersListFetching,
    isLoading: adminUsersListLoading,
    refetch: refetchAdminUsersList,
    fetchNextPage: adminUsersListFetchNextPage,
    hasNextPage: adminUsersListHasNextPage,
    hasPreviousPage: adminUsersListHasPrevPage,
    fetchPreviousPage: adminUsersListFetchPrevPage,
  } = useInfiniteQuery({
    queryKey: ["admin-users-list", searchQuery],
    queryFn: async ({ queryKey, pageParam }) => {
      const resp = (
        await dispatch(getListAdminUser({ pageParam, search: queryKey?.[1] }))
      )?.payload;
      return resp;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    getPreviousPageParam: (lastPage) => getNextPageFromURL(lastPage?.previous),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
    refetchAdminUsersList?.();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      refetchAdminUsersList();
    }
  }, [searchQuery]);

  const sort = 10;

  const activePag = useRef(0);

  const allUsers = flattenQueryResults(data) ?? [];

  const handleChangeUserRole = (action, id) => {
    dispatch(changeUserAdminRole({user_id:id, role:action === 'REMOVE' ? null : 1})).then((res) => {
      if (res.payload.message) {
        refetchAdminUsersList();
        toast.success(res.payload.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
        });
      } else {
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      }
    });
  }

    // console.log(allUsers);

  return (
    <Fragment>
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Admin Users</h4>
          </div>
          <div className="card-body">
            {adminUsersListFetching || adminUsersListLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <div className="w-100 table-responsive">
                <div id="example_wrapper" className="dataTables_wrapper">
                  {/* <div id="example_filter" class="dataTables_filter">
                  <label>
                    Search:
                    <input
                      type="search"
                      class=""
                      placeholder=""
                      aria-controls="example"
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </label>
                </div> */}
                  {adminUsersListLoading || adminUsersListFetching ? (
                    <SectionLoader />
                  ) : (
                    <>
                      <table id="example" className="display w-100 dataTable">
                        <thead>
                          <tr role="row">
                            {columns.map((d, i) => (
                              <th key={i}>{d}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {allUsers?.length ? (
                            allUsers?.map((d, i) => (
                              <tr key={i}>
                                <Fragment key={i}>
                                  <td>{`${d?.first_name} ${d?.last_name}`}</td>
                                  <td>{d?.email}</td>
                                  <td>{d?.location}</td>
                                  <td>
                                    {d?.role === 1 ? "Super Admin" : "Admin"}
                                  </td>
                                  <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="info"
                                    className="light sharp i-false"
                                  >
                                    {svg1}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {userProfileInfo?.role === 1 && <Dropdown.Item
                                      onClick={() =>
                                        handleChangeUserRole("REMOVE", d?.id)
                                      }
                                    >
                                      Remove admin
                                    </Dropdown.Item>}
                                    {userProfileInfo?.role === 1 && userProfileInfo.id !== d?.id ? <Dropdown.Item
                                      onClick={() =>
                                        handleChangeUserRole("SUPERADMIN", d?.id)
                                      }
                                    >
                                      Make super admin
                                    </Dropdown.Item> : <></>}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                                </Fragment>
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </tbody>
                        <tfoot>
                          <tr role="row">
                            {columns.map((d, i) => (
                              <th key={i}>{d}</th>
                            ))}
                          </tr>
                        </tfoot>
                      </table>

                      <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                          Showing {activePag.current * sort + 1} to{" "}
                          {allUsers?.length} of {data?.pages[0]?.count} entries
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                          >
                            {adminUsersListHasPrevPage && (
                              <Link
                                className="paginate_button previous disabled"
                                onClick={() => adminUsersListFetchPrevPage()}
                              >
                                Previous
                              </Link>
                            )}

                            {adminUsersListHasNextPage && (
                              <Link
                                className="paginate_button next"
                                onClick={() => adminUsersListFetchNextPage()}
                              >
                                Next
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AdminUsersList;
