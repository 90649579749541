import { createReducer } from '@reduxjs/toolkit';
import {
    getUserProfileInfo,
    userProfileAddInfo,
    createAdminUser,
    getDahsboardData
} from '../actions/user-profile.actions';

const initialState = {
    msg: '',
    loading: true,
    error: false,
    userProfileInfo: {},
    isProfileUpdated: false,
    isLoading:false,
    createAdminResponse:null,
    dasboardData:null
};

export const userProfileReducer = createReducer(initialState, builder => {
    builder
        .addCase(userProfileAddInfo.pending, (state, action) => {
            state.isProfileUpdated = false;
            state.loading = true;
        })
        .addCase(userProfileAddInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.isProfileUpdated = true;
        })
        .addCase(userProfileAddInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getUserProfileInfo.pending, (state, action) => {
            state.loading = true;
            state.isProfileResult = false;
        })
        .addCase(getUserProfileInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.userProfileInfo = action.payload;
            state.isProfileResult = true;
        })
        .addCase(getUserProfileInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(createAdminUser.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(createAdminUser.fulfilled, (state, action) => {
            state.loading = false;
            state.createAdminResponse = action.payload;
        })
        .addCase(createAdminUser.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
        .addCase(getDahsboardData.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getDahsboardData.fulfilled, (state, action) => {
            state.loading = false;
            state.dasboardData = action.payload;
        })
        .addCase(getDahsboardData.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Try again later or contact customer support';
        })
});